import React, { memo, useEffect, useState } from 'react';
import { Form, message, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import axios from '@axios';
import { Button } from '@styles/components/button';
import { FormItem, Select, Option } from '@styles/components/form';
import { useQuery as useQueryParams } from '@hooks/use-query';
import { orderTypeSelector } from '@redux/slices/order';
import { TypeResponseApi } from '@models/order/type/response';
import { translate } from '@components/i18n';
import { transferOrderSelector } from '@redux/slices/transfer-order/order';
import { updateTransferBranch, updateTransferMoneyType } from '@redux/slices/transfer-order/order';
import { moneyTypeSelector } from '@redux/slices/money-type';
import { Moeda } from '@models/order/moedas/response';

type FormType = {
    filialCode: string;
    moneyType: Moeda;
}

export const SelectTypeForm: React.FC = memo(() => {
    const transferOrder = useSelector(transferOrderSelector);
    const selectTypeState = useSelector(orderTypeSelector);
    const moneyType = useSelector(moneyTypeSelector)

    const query = useQueryParams()
    const isDraft = query.get('draft')
    const history = useHistory();
    const dispatch = useDispatch();

    const defaultValues = {
        filialCode: transferOrder?.filial?.id,
    }

    const [form] = Form.useForm<FormType>();
    const [formIsValid, setFormIsValid] = useState<boolean>(false)

    const goBack = (): void => history.goBack();

    const { data, refetch } = useQuery('suggestion', async () => {
        if (!transferOrder?.client?.id) {
            throw new Error()
        }

        const result = await axios.get<TypeResponseApi>(
            `/filial/byusuario/cliente/${transferOrder.distributor.codigo}?companhiaCodigo=${transferOrder.distributor.codigoCompanhia}`,
        );

        const data = Object.assign(new TypeResponseApi(), result.data);

        return data.toTypeResponse();
    });


    useEffect(() => {
        if (data && selectTypeState.allAvailablePlatforms.length > 0) {
            form.setFieldsValue({ filialCode: data.id });
            setFormIsValid(true);
        } else refetch();
    }, [data, selectTypeState.allAvailablePlatforms]);

    useEffect(() => {
        if (moneyType?.available?.length === 1) {
            form.setFieldsValue({ moneyType: moneyType.available[0] });
        }
    }, [moneyType.isSuccess])

    const onSubmit = (): void => {
        const selectedMoneyType = form.getFieldValue('moneyType')

        if (!selectedMoneyType) {
            message.error(translate('general.moedaAvailableError'), 3);
            return
        }
        dispatch(updateTransferMoneyType({
            moneyType: selectedMoneyType,
        }))

        const filial = selectTypeState.allAvailablePlatforms.find(t => t.id === form.getFieldValue("filialCode"))

        if (!filial) {
            message.error(translate('general.filialAvailableError'), 3);
            return
        }

        dispatch(updateTransferBranch({
            filial,
        }))


        let params = ''

        if (isDraft) {
            const ref = query.get('ref')
            params = `?draft=true&&ref=${ref}`
        }

        history.push('/create-transfer-order/select-items' + params);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            onValuesChange={(_, t) => {
                setFormIsValid(!!t.filialCode)
            }}
            initialValues={defaultValues}
        >
            <FormItem
                label={translate('forms.labels.originPlatform')}
                name={'filialCode'}
            >
                <Select
                    placeholder={translate('forms.placeholders.chooseOption')}
                    loading={selectTypeState.isFetchingPlatform}
                    allowClear
                    disabled={!!isDraft}
                >
                    {selectTypeState.allAvailablePlatforms.map((t) => (
                        <Option value={t.id}>
                            {t.id} - {t.description}
                        </Option>
                    ))}
                </Select>
            </FormItem>
            <Form.Item>
                <Row justify={'space-between'}>
                    <Button
                        htmlType="button"
                        type="primary"
                        ghost
                        fontWeight={500}
                        onClick={goBack}
                    >
                        {translate('forms.buttons.changeClient')}
                    </Button>
                    <Button
                        htmlType="submit"
                        type="primary"
                        fontWeight={500}
                        onClick={onSubmit}
                        disabled={!formIsValid}
                    >
                        {translate('forms.buttons.continue')}
                    </Button>
                </Row>
            </Form.Item>
        </Form>
    );
});
