import React, { memo, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@styles/components/button';
import { Modal } from '@components/modal';
import { translate } from '@components/i18n';
import { Select, FormItem, Option } from '@styles/components/form';
import { fetchAvailablePlatforms, orderItemsSelector, orderTypeSelector, requestChangeBranch, requestVerifyChangeBranch, updatePlatform } from '@redux/slices/order';

type TModalRes = {
  message: string,
  isVisible: boolean
}

export const ChangeBranch: React.FC = memo(() => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [modalRes, setModalRes] = useState<TModalRes>({
    isVisible: false,
    message: ''
  })
  const [form] = Form.useForm();
  const selectTypeState = useSelector(orderTypeSelector);
  const selectOrderItemsSelector = useSelector(orderItemsSelector);
  const history = useHistory();

  const selected = selectTypeState.selectedPlatform
    ? selectTypeState.selectedPlatform.id
    : selectOrderItemsSelector?.order.orderType?.platform?.id

  const onSubmit = async () => {
    setIsVisible(false)

    const platformId = form.getFieldValue("platform")

    if (!platformId) {
      return
    }

    const platform = selectTypeState.availablePlatforms.find(item => item.id === platformId)
    if (!platform) {
      return
    }

    if (platformId !== selected) {
      dispatch(requestVerifyChangeBranch({
        data: {
          codigoFilial: parseInt(platform.id),
          pedidoId: selectOrderItemsSelector.orderApi.id
        },
        onError: (message: string) => {
          setModalRes({ isVisible: true, message })
          form.setFieldsValue({
            platform: selected
          })
        },
        onSuccess: () => {
          dispatch(updatePlatform(platform))
        }
      }))
    }
  }

  useEffect(() => {
    if (isVisible && selectOrderItemsSelector?.order?.customer?.company) {
      dispatch(fetchAvailablePlatforms({ companhiaCodigo: selectOrderItemsSelector.order.customer.company }));
    }
  }, [isVisible])

  return (
    <>
      <Button
        darkBlue
        height={40}
        onClick={() => {
          setIsVisible(true)
        }}>
        {translate("general.changeBranch")}
      </Button>

      <Modal
        visible={modalRes?.isVisible}
        title={translate("general.errorT")}
        onCancel={() => {
          setModalRes({ isVisible: false, message: '' })
        }}
        onOk={() => {
          setModalRes({ isVisible: false, message: '' })
          history.push("/")
        }}
        okText={translate("general.newOrder")}
      >
        {modalRes?.message}
      </Modal>

      <Modal
        title={translate('general.changeBranch')}
        destroyOnClose={true}
        className="bold-title"
        visible={isVisible}
        width="30%"
        onCancel={() => setIsVisible(false)}
        onOk={onSubmit}
      >
        <Form
          form={form}
          layout="vertical"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          initialValues={{
            platform: selected,
          }}
        >
          <FormItem
            label={translate('forms.labels.originPlatform')}
            name="platform"
          >
            <Select
              placeholder={translate('forms.placeholders.chooseOption')}
              loading={selectTypeState.isFetchingPlatform}
              allowClear
            >
              {selectTypeState.availablePlatforms.map((t) => (
                <Option value={t.id}>
                  {t.id} - {t.description}
                </Option>
              ))}
            </Select>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
});
