import selectCustomerReducer from './select-customer';
import selectTypeReducer from './select-type';
import selectItemsReducer from './select-items';
import draftsReducer from './drafts';
import scheduledReducer from './scheduled';
import bonificationReducer from './bonification';
import approvalReducer from './approval';
import historyReducer from './history';
import financialDiscounts from './financial-discounts';
import sellerReducer from './select-seller';
export * from './select-customer';
export * from './select-seller';
export * from './select-type';
export * from './select-items';
export * from './drafts';
export * from './bonification';
export * from './approval';

export default {
    selectCustomer: selectCustomerReducer,
    selectSeller: sellerReducer,
    selectType: selectTypeReducer,
    selectItems: selectItemsReducer,
    drafts: draftsReducer,
    bonification: bonificationReducer,
    scheduled: scheduledReducer,
    approval: approvalReducer,
    history: historyReducer,
    financialDiscounts: financialDiscounts,
};
