/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { EditableField } from '@models/editable-field';
import { translate } from '@components/i18n';
import { useSelector } from 'react-redux';
import { authSelector } from '@redux/slices/auth';
import { userSelector } from '@redux/slices/users';

export const columns = [
    {
        title: translate('columns.client'),
        dataIndex: 'razaoSocial',
        sorter: true,
        width: '15%',
    },
    {
        title: translate('columns.cnpj'),
        dataIndex: 'cnpj',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.email'),
        dataIndex: 'email',
        sorter: true,
        width: '15%'
    },
    {
        title: translate('columns.promoter'),
        dataIndex: 'promotor',
        sorter: false,
        width: '15%',
        render: (_, record): React.ReactNode => {
            const users = useSelector(userSelector)
            const user = users?.availableUsers?.find(user => user.id === record.idPromotor)
            return (
                <span>
                    {user?.name || ''}
                </span>
            );
        },
    },
    {
        title: translate('columns.distributor'),
        dataIndex: 'codigoDistribuidor',
        sorter: true,
        width: '15%',
        render: (_, record): JSX.Element => {
            const codeName = `${record.codigoDistribuidor} - ${record.nomeDistribuidor || ''}`
            return (
                <span>{codeName}</span>
            )
        }
    },
    {
        title: translate('columns.city'),
        dataIndex: 'cidade',
        sorter: true,
        width: '15%',
    },
    {
        title: translate('columns.state'),
        dataIndex: 'zona',
        sorter: true,
        width: '10%',
    },
];

export function editableFields({
    promoters,
    distributors,
}): EditableField[] {
    const { isAdmin } = useSelector(authSelector)

    const fields = [
        {
            name: 'razaoSocial',
            label: translate('forms.labels.companyName'),
            type: 'text',
            span: 24,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
                {
                    min: 5,
                    message: translate('forms.rules.fiveCaracteres'),
                },
            ],
        },
        {
            name: 'nomeFantasia',
            label: translate('forms.labels.fantasyName'),
            type: 'text',
            span: 24,
        },
        {
            name: 'cnpj',
            label: translate('forms.labels.cnpj'),
            type: 'text',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'email',
            label: translate('forms.labels.email'),
            type: 'text',
            span: 12,
            rules: [
                {
                    type: 'email',
                    message: translate('forms.rules.validEmail'),
                },
            ],
        },
        {
            name: 'idPromotor',
            label: translate('forms.labels.promotor'),
            type: 'select',
            span: 24,
            options: promoters,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'endereco1',
            label: translate('forms.labels.address'),
            type: 'text',
            span: 24,
        },
        {
            name: 'contato',
            label: translate('forms.labels.contact'),
            type: 'text',
            span: 12,
        },
        {
            name: 'telefone',
            label: translate('forms.labels.phone'),
            type: 'text',
            span: 12,
        },
        {
            name: 'bairro',
            label: translate('forms.labels.neighborhood'),
            type: 'text',
            span: 12,
        },
        {
            name: 'cidade',
            label: translate('forms.labels.city'),
            type: 'text',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'zona',
            label: translate('forms.labels.state'),
            type: 'text',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'codigoPostal',
            label: translate('forms.labels.postalCode'),
            type: 'text',
            span: 12,
        },
        {
            name: 'codigoDistribuidor',
            label: translate('forms.labels.distributor'),
            type: 'select',
            span: 24,
            options: distributors,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
    ];

    if (!isAdmin) {
        return fields.filter(item => item.name !== 'idPromotor') as EditableField[]
    }


    return fields as EditableField[]
}



export function promoterFields({
    promoters,
}): EditableField[] {

    const fields = [
        {
            name: 'idPromotorDe',
            label: translate('forms.labels.promotorFrom'),
            type: 'select',
            span: 24,
            options: promoters,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (value && getFieldValue('idPromotorPara') === value) {
                            return Promise.reject(
                                new Error(translate('forms.rules.promotersCanNotBeEquals')),
                            );
                        }
                        return Promise.resolve();
                    },
                }),
            ],
        },
        {
            name: 'idPromotorPara',
            label: translate('forms.labels.promotorTo'),
            type: 'select',
            span: 24,
            options: promoters,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (value && getFieldValue('idPromotorDe') === value) {
                            return Promise.reject(
                                new Error(translate('forms.rules.promotersCanNotBeEquals')),
                            );
                        }
                        return Promise.resolve();
                    },
                }),
            ],
        },
    ];


    return fields as EditableField[]
}
