import React, { memo, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@styles/components/button';
import { Modal } from '@components/modal';
import { translate } from '@components/i18n';
import { fetchSellers, orderItemsSelector, sellerSelector, updateSeller } from '@redux/slices/order';
import { Table } from '@components/table';
import { UserResponseApi } from '@models/users';
import { Input } from '@components/input';
import { SearchOutlined } from '@ant-design/icons';

const columns = [
  {
    title: translate('columns.name'),
    dataIndex: 'nome',
    sorter: false,
    width: '30%',
  },
  {
    title: translate('columns.jdeCode'),
    dataIndex: 'codigoJDE',
    sorter: false,
    width: '30%',
  },
  {
    title: translate('columns.division'),
    dataIndex: 'divisao',
    sorter: true,
    width: '30%',
  },
];

export const ChangeSeller: React.FC = memo(() => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const seller = useSelector(sellerSelector);
  const selectOrderItemsSelector = useSelector(orderItemsSelector);
  const [selectedRow, setSelectedRow] = useState<string>()
  const [query, setQuery] = useState<string>('')

  const userId = seller?.selectedSeller ? seller.selectedSeller.id : selectOrderItemsSelector.orderApi.usuarioId

  const onSubmit = async () => {
    setIsVisible(false)

    if (!selectedRow) return;

    const selected = parseInt(selectedRow)

    if (userId === selected) return

    const sellerSelected = seller.availableSellers.find(sel => sel.id === selected)

    if (!sellerSelected?.codigoJDE) return

    dispatch(updateSeller(sellerSelected))
  }

  const onSelect = (rowKey: UserResponseApi): void => {
    setSelectedRow(rowKey.id.toString())
  };

  useEffect(() => {
    if (isVisible) {
      dispatch(fetchSellers(selectOrderItemsSelector.orderApi.id))
      if (userId) {
        setSelectedRow(userId.toString());
      }
    }
  }, [isVisible])

  const filteredUsers = () => {
    if (!query) return seller.availableSellers
    const items = seller.availableSellers
      .filter(item => {
        return item.nome.toLowerCase().includes(query.toLowerCase()) ||
          item.codigoJDE.toString().includes(query) ||
          item.divisao.toLowerCase().includes(query.toLowerCase())
      })
    return items
  }

  return (
    <>
      <Button
        darkBlue
        height={40}
        onClick={() => {
          setIsVisible(true)
        }}>
        {translate("general.changeSeller")}
      </Button>

      <Modal
        title={translate('general.changeSeller')}
        destroyOnClose={true}
        className="bold-title"
        visible={isVisible}
        width="60%"
        onCancel={() => setIsVisible(false)}
        onOk={onSubmit}
      >
        <Table
          columns={columns}
          pointer
          rowSelection={{
            type: "radio",
            columnWidth: '20px',
            selectedRowKeys: [selectedRow],
            fixed: true,
            onChange: (e) => {
              if (e && e.length > 0) {
                setSelectedRow(e[0] as string)
              }
            }
          }}
          rowKey={(record: UserResponseApi) => `${record.id}`}
          dataSource={filteredUsers()}
          onRowClick={onSelect}
          isLoading={seller.isFetching}
          filters={[
            <Form.Item>
              <Input
                placeholder={translate('forms.placeholders.search')}
                allowClear
                suffix={
                  <div
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <SearchOutlined />
                  </div>
                }
                onChange={(e) => setQuery(e.target.value)}
              />
            </Form.Item>,
          ]}
        />
      </Modal>
    </>
  );
});
