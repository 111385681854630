import { Col, Form, Row, Switch } from 'antd';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormItem, Select, Option } from '@styles/components/form';
import { translate } from '@components/i18n';
import { DO_NOT_SHOW_MONEY_SELECTION } from '@config/env';
import { transferOrderSelector, updateTransferUpdateDeliveryClientCode, updateTransferUpdateShowDeliveryClientModal } from '@redux/slices/transfer-order/order';
import { moneyTypeSelector } from '@redux/slices/money-type';

export const SelectItemsFilters: React.FC = memo(() => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const transferOrder = useSelector(transferOrderSelector);
    const moneyType = useSelector(moneyTypeSelector);

    const onValuesChange = (changedValues: any) => {
        if ("addCustomer" in changedValues) {
            if (!changedValues.addCustomer) {
                dispatch(updateTransferUpdateDeliveryClientCode(undefined))
            }
            dispatch(updateTransferUpdateShowDeliveryClientModal(changedValues.addCustomer))
        }
    }

    return (
        <Row justify='end' style={{ width: '100%' }}>
            <Form
                form={form}
                layout="inline"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 24 }}
                onValuesChange={onValuesChange}
                style={{ width: '100%', display: 'flex', justifyContent: 'end' }}
                initialValues={{
                    moneyType: transferOrder?.moneyType?.codigo,
                    addCustomer: !!transferOrder?.showModalDeliveryClient
                }}
            >
                <Row justify='end' style={{ width: '100%' }}>
                    {!DO_NOT_SHOW_MONEY_SELECTION && (
                        <Col xs={24} md={12} lg={6} xxl={5}>
                            <FormItem
                                label={translate('forms.labels.moneyType')}
                                name="moneyType"
                            >
                                <Select
                                    placeholder={translate('forms.placeholders.chooseOption')}
                                    allowClear
                                    disabled
                                    value={transferOrder?.moneyType?.codigo}
                                >
                                    {moneyType?.available?.map((t) => (
                                        <Option value={t.codigo}>
                                            {`${t.codigo} - ${t.descricao}`}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Col>
                    )}
                    <Col xs={24} md={12} lg={5} xxl={4}>
                        <FormItem
                            label={translate("general.deliveryClient")}
                            name="addCustomer"
                        >
                            <Switch checked={!!transferOrder?.deliveryClient} />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </Row>
    );
});
