/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { Col, Form, Row, Switch } from 'antd';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import locale from 'antd/es/date-picker/locale/pt_BR';
import localeEs from 'antd/es/date-picker/locale/es_ES';
import { Input, InputDate } from '@components/input';
import {
    fetchAvailableItems,
    orderItemsSelector,
    updateOrder,
} from '@redux/slices/order';
import { FormItem, Select, Option } from '@styles/components/form';
import { useQuery as useQueryHooks } from '@hooks/use-query';
import { fetchCampaignsForOrder, selectCampaign } from '@redux/slices/campaign';
import { isEsAr, translate } from '@components/i18n';
import { isEsMx } from '@components/i18n';
import { SHOW_DELIVERY_DATE, ENABLE_PAYMENT_CONDITION, DO_NOT_SHOW_MONEY_SELECTION } from '@config/env';

interface SelectItemsHeaderProps {
    readOnly?: boolean;
    marketing?: boolean;
    bonification?: boolean;
}


export const SelectItemsFilters: React.FC<SelectItemsHeaderProps> = memo(({ bonification, marketing }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const query = useQueryHooks();
    const selectItemsState = useSelector(orderItemsSelector);
    const isBonification = selectItemsState.order?.paymentCondition?.id === '100'

    useEffect(() => {
        const isDraft = query.get('draft');

        if (
            selectItemsState.paymentCondition && selectItemsState.paymentCondition.id &&
            selectItemsState.availablePaymentConditions.length > 0 &&
            !isDraft
        ) {
            form.setFieldsValue({
                paymentConditionId: selectItemsState.paymentCondition.id,
                deliveryDate: undefined,
            });
        } else if (
            selectItemsState.paymentCondition && selectItemsState.paymentCondition.id &&
            !selectItemsState.request.paymentConditionId &&
            !isDraft
        ) {
            form.setFieldsValue({
                paymentConditionId: undefined,
                deliveryDate: undefined,
            });
        }
    }, [selectItemsState.paymentCondition, selectItemsState.availablePaymentConditions]);


    const onValuesChange = (changedValues: any, allValues: any): void => {
        if (changedValues.paymentConditionId)
            dispatch(
                updateOrder({
                    ...allValues,
                    paymentCondition: {
                        id: changedValues.paymentConditionId,
                        description:
                            selectItemsState.availablePaymentConditions.find(
                                (c) =>
                                    `${c.id}` ===
                                    `${changedValues.paymentConditionId}`,
                            ).description,
                    },
                }),
            );
        else dispatch(updateOrder(allValues));

        if (changedValues.paymentConditionId && changedValues.paymentConditionId !== selectItemsState.paymentCondition?.id) {
            dispatch(
                fetchAvailableItems({
                    paymentConditionId: allValues.paymentConditionId,
                    endDate: allValues.deliveryDate,
                    tempItems: selectItemsState.availableItems.filter(
                        (c) => c.quantity > 0,
                    ),
                }),
            );
            dispatch(selectCampaign([]));
        }
    };

    const updateScheduledDate = (date: any): void => {
        if (!date) return

        dispatch(
            updateOrder({
                ...selectItemsState.order,
                orderType: {
                    ...selectItemsState.order.orderType,
                    date,
                },
            }),
        );

        if (selectItemsState?.request?.paymentConditionId) {
            dispatch(
                fetchCampaignsForOrder({
                    paymentConditionId: selectItemsState.request.paymentConditionId,
                    date,
                }),
            );
        }
    };

    if (bonification && marketing) {
        return (
            <Row justify='end' style={{ width: '100%' }}>
                <Form
                    form={form}
                    layout="inline"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 24 }}
                    style={{ width: '100%', display: 'flex', justifyContent: 'end' }}
                    onValuesChange={onValuesChange}
                    initialValues={{
                        ...selectItemsState.order,
                        moneyType: selectItemsState?.selectedMoeda?.codigo,
                        paymentConditionId:
                            selectItemsState.order?.paymentCondition?.id,
                        deliveryDate: selectItemsState.order?.deliveryDate
                            ? moment(selectItemsState.order?.deliveryDate)
                            : undefined,
                    }}
                >
                    {query.get('scheduled') && (
                        <Col xs={24} md={12} lg={6}>
                            <FormItem
                                label={translate('forms.labels.scheduledDate')}
                            >
                                <InputDate
                                    locale={isEsAr() || isEsMx() ? localeEs : locale}
                                    format={'DD/MM/YYYY'}
                                    disabledDate={(current) => {
                                        return moment() >= current;
                                    }}
                                    placeholder={translate(
                                        'forms.placeholders.chooseTheDate',
                                    )}
                                    defaultValue={
                                        selectItemsState.order.orderType.date
                                            ? moment(
                                                selectItemsState.order.orderType.date,
                                            )
                                            : undefined
                                    }
                                    onChange={updateScheduledDate}
                                />
                            </FormItem>
                        </Col>
                    )}
                </Form>
            </Row>
        )
    }

    return (
        <Row justify='end' style={{ width: '100%' }}>
            <Form
                form={form}
                layout="inline"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '100%', display: 'flex', justifyContent: 'end' }}
                onValuesChange={onValuesChange}
                initialValues={{
                    ...selectItemsState.order,
                    moneyType: selectItemsState?.selectedMoeda?.codigo,
                    paymentConditionId:
                        selectItemsState.order?.paymentCondition?.id,
                    deliveryDate: selectItemsState.order?.deliveryDate
                        ? moment(selectItemsState.order?.deliveryDate)
                        : undefined,
                }}
            >
                <Row justify='end' style={{ width: '100%' }}>
                    {!DO_NOT_SHOW_MONEY_SELECTION && (
                        <Col xs={24} md={12} lg={6} xxl={5}>
                            <FormItem
                                label={translate('forms.labels.moneyType')}
                                name={'moneyType'}
                            >
                                <Select
                                    placeholder={translate('forms.placeholders.chooseOption')}
                                    allowClear
                                    disabled
                                    value={selectItemsState?.selectedMoeda?.codigo}
                                >
                                    {selectItemsState.moedas.map((t) => (
                                        <Option value={t.codigo}>
                                            {`${t.codigo} - ${t.descricao}`}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Col>
                    )}
                    <Col xs={24} md={12} lg={6} xxl={5}>
                        <FormItem
                            label={translate('forms.labels.buyOrder')}
                            name={'order'}
                        >
                            <Input
                                placeholder={translate(
                                    'forms.placeholders.writeTheData',
                                )}
                            />
                        </FormItem>
                    </Col>
                    <Col xs={24} md={12} lg={6} xxl={5}>
                        <FormItem
                            label={translate('forms.labels.paymentCondition')}
                            name={'paymentConditionId'}
                        >
                            <Select
                                placeholder={translate(
                                    'forms.placeholders.chooseOption',
                                )}
                                loading={
                                    selectItemsState.isFetchingPaymentCondition
                                }
                                dropdownMatchSelectWidth={false}
                                disabled={(!ENABLE_PAYMENT_CONDITION || isBonification)}
                            >
                                {selectItemsState.availablePaymentConditions
                                    .filter((c) => `${c.id}` !== `100`)
                                    .map((t) => (
                                        <Option value={t.id}>
                                            {t.description}
                                        </Option>
                                    ))}
                            </Select>
                        </FormItem>
                    </Col>
                    {SHOW_DELIVERY_DATE && (
                        <Col xs={24} md={12} lg={6} xxl={4}>
                            <FormItem
                                label={translate('forms.labels.deliveryDate')}
                                name={'deliveryDate'}
                            >
                                <InputDate
                                    locale={isEsAr() || isEsMx() ? localeEs : locale}
                                    format={'DD/MM/YYYY'}
                                    disabledDate={(current) => {
                                        return moment() >= current;
                                    }}
                                    placeholder={translate(
                                        'forms.placeholders.chooseTheDate',
                                    )}
                                />
                            </FormItem>
                        </Col>
                    )}
                    <Col xs={24} md={12} lg={5} xxl={3}>
                        <FormItem label={translate("general.deliveryClient")} name="addCustomer">
                            <Switch checked={selectItemsState.order?.addCustomer} />
                        </FormItem>
                    </Col>
                    {query.get('scheduled') && (
                        <Col xs={24} md={12} lg={5} xxl={4}>
                            <FormItem
                                label={translate('forms.labels.scheduledDate')}
                            >
                                <InputDate
                                    locale={isEsAr() || isEsMx() ? localeEs : locale}
                                    format={'DD/MM/YYYY'}
                                    disabledDate={(current) => {
                                        return moment() >= current;
                                    }}
                                    placeholder={translate(
                                        'forms.placeholders.chooseTheDate',
                                    )}
                                    defaultValue={
                                        selectItemsState.order.orderType.date
                                            ? moment(
                                                selectItemsState.order.orderType.date,
                                            )
                                            : undefined
                                    }
                                    onChange={updateScheduledDate}
                                />
                            </FormItem>
                        </Col>
                    )}
                </Row>
            </Form>
        </Row>
    );
});
