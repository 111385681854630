import { EyeOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'
import { translate } from '@components/i18n';
import { TransferOrder } from '@models/transfer-order';
import { moneySymbolsSelector } from '@redux/slices/money-type';
import { Button } from '@styles/components/button';
import { formatMoney } from '@utils/money';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Comparison from 'src/enums/comparison';

export const historyColumns = [
    {
        title: translate('columns.order'),
        dataIndex: 'id',
        sorter: false,
        width: '8%',
    },
    {
        title: translate('columns.saleOrder'),
        dataIndex: 'pedidoVendaId',
        sorter: false,
        width: '8%',
    },
    {
        title: translate('columns.client'),
        dataIndex: 'razaoSocial',
        width: '25%',
    },
    {
        title: translate('columns.division'),
        dataIndex: 'divisao',
        width: '8%',
    },
    {
        title: translate('columns.distributorCode'),
        dataIndex: 'codigoDistribuidor',
        width: '15%',
        render: (_, record): JSX.Element => {
            const codeName = `${record.codigoDistribuidor} - ${record.nomeDistribuidor || ''}`
            return (
                <span>{codeName}</span>
            )
        }
    },
    {
        title: translate('columns.orderDate'),
        dataIndex: 'dataPedido',
        sorter: false,
        width: '10%',
        render: (_, record): React.ReactNode => {
            return (
                <span>
                    {moment(record.dataPedido).format('DD/MM/YYYY')}
                </span>
            );
        },
    },
    {
        title: translate('columns.status'),
        dataIndex: 'status',
        sorter: false,
        width: '15%',
        render: (_, record) => {
            const status = filterTypesStatus?.find(item => item.name === record.status)

            return (
                <span>
                    {status?.description}
                </span>
            )
        }
    },
    {
        title: translate('columns.total'),
        dataIndex: 'valorTotal',
        sorter: false,
        width: '12%',
        render: (_, record: TransferOrder.Response): JSX.Element => {
            const total = record?.valorTotal
            const moneyCode = record.codigoMoeda
            const symbols = useSelector(moneySymbolsSelector)
            const symbol = symbols.find(item => item.codigoMoeda === moneyCode)

            return (
                <span>{formatMoney(symbol?.simbolo, total || 0)}</span>
            )
        }
    },
    {
        title: translate('columns.salesOrder'),
        dataIndex: 'salesOrder',
        sorter: false,
        width: '15%',
        render: (_, record: TransferOrder.Response) => {
            const history = useHistory();

            const hashSalesOrder = (record.status === 'FATURADO' && !!record.pedidoVendaId)

            let title = ''
            if (hashSalesOrder) {
                title = translate('general.viewSalesOrder')
            } else {
                title = translate('general.notAvailable')
            }

            const goToSalesOrder = () => {
                history.push(`/approval-order/${record.pedidoVendaId}?readOnly=true`)
            }

            return (
                <Button
                    type="primary"
                    ghost
                    height={35}
                    width={35}
                    title={title}
                    disabled={!hashSalesOrder}
                    onClick={goToSalesOrder}
                    data-event="sales-order"
                >
                    <EyeOutlined style={{ pointerEvents: 'none' }} />
                </Button>
            );
        },
    },
];

export const filterTypes = [
    {
        name: 'PEDIDO',
        description: translate('filters.order'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'PEDIDO_VENDA',
        description: translate('filters.saleOrder'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'VALOR_TOTAL',
        description: translate('filters.totalValue'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'DATA',
        description: translate('filters.date'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'DIVISAO',
        description: translate('filters.division'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
    {
        name: 'CODIGO_DISTRIBUIDOR',
        description: translate('filters.distributorCode'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'NOME_DISTRIBUIDOR',
        description: translate('filters.distributorName'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
    {
        name: 'CODIGO_CLIENTE_TRANSFER',
        description: translate('filters.transferClientCode'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'NOME_CLIENTE_TRANSFER',
        description: translate('filters.transferClientName'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
    {
        name: 'STATUS',
        description: translate('filters.status'),
    },
];

export const filterTypesStatus = [
    {
        name: 'NAO_FATURADO',
        description: translate('general.noBilled'),
    },
    {
        name: 'FATURADO',
        description: translate('general.billed'),
    },
    {
        name: 'RASCUNHO',
        description: translate('general.draft'),
    },
];
