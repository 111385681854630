import moment from 'moment';
import { OrderRequest } from './items/request';

export class OrderResponseApi {
    id: number;
    ultimaAtualizacao: null;
    codigoTipoPedido: string;
    descricaoTipoPedido: string;
    usuarioId: number;
    usuarioNome: string;
    emailCopia: null;
    comentario: null;
    codigoPrazo: string;
    descricaoPrazo: string;
    dataPedido: string;
    dataProgramada: string;
    dataEntrega: string;
    valorTotal: number;
    valorTotalComIpi: number;
    status: string;
    protocolo: string;
    divisao: string;
    codigoCliente: number;
    nomeCliente: string;
    cnpjCliente: string;
    tipoCliente: string;
    cidadeCliente: string;
    zonaCliente: string;
    codigoFilial: string;
    descricaoFilial: string;
    codigoClienteEntrega: number;
    nomeClienteEntrega: string;
    cnpjClienteEntrega: string;
    ordemCompra: string;
    bonificacao: boolean;
    pedidoBonificacaoId: string;
    itens: Iten[];
    aprovacoes: any[];
    semaforoCor: string;
    semaforoNome: string;
    descontoPonderado: number;
    valorSemPTAX?: number;
    somaTotalCusto?: number;
    itensBonificados: ItensBonificado[];
    aprovadorMarketingUsuarioId?: number;
    campanhaIds?: number[];
    envioProximoPedidoVenda?: boolean;
    codigoMoeda?: string;
    pedidoCopia?: boolean;

    toOrderRequest(): OrderRequest {
        //
        return {
            customer: {
                code: this.codigoCliente,
                name: this.nomeCliente,
                id: this.codigoCliente,
                cnpj: this.cnpjCliente,
                typeId: this.tipoCliente
            },
            codigoMoeda: this.codigoMoeda,
            campanhaIds: this.campanhaIds ? this.campanhaIds : [],
            orderType: {
                platform: {
                    id: this.codigoFilial,
                    description: this.descricaoFilial,
                },
                date: this.dataProgramada,
                type: {
                    id: this.codigoTipoPedido,
                    description: this.descricaoTipoPedido,
                },
            },
            valorPedido: this.valorTotal,
            deliveryDate: this.dataEntrega,
            bonificationItems: this.itensBonificados.map((i) => ({
                discount: i.desconto,
                description: i.descricaoItem,
                ipi: i.ipi,
                quantityPresentation: i.multiplo,
                quantity: i.quantidade,
                number: i.numeroItem,
                code: i.numeroItem,
                total: i.precoTotal,
                order: i.ordem,
                totalQuantity: i.quantidadeTotal,
                promotion: i.promocoes,
                costModifiable: i.precoUnitario,
                valor: i.precoUnitario,
                semaforo: i.semaforoCor,
                semaforoNome: i.semaforoNome,
                defaultKey: this.id,
            })),
            addCustomer: !!this.nomeClienteEntrega,
            sendCopy: {
                email: this.emailCopia,
                note: this.comentario,
                envioProximoPedidoVenda: this.envioProximoPedidoVenda,
            },
            paymentCondition: {
                id: this.codigoPrazo,
                description: this.descricaoPrazo,
            },
            order: this.ordemCompra,
            items: [],
            availableItems: [],
            semaforoPedido: this.semaforoCor,
            semaforoNomePedido: this.semaforoNome,
            valorSemPTAX: this.valorSemPTAX,
            somaTotalCusto: this.somaTotalCusto,
            tempItems: this.itens.map((i) => ({
                discount: i.desconto,
                description: i.descricaoItem,
                ipi: i.ipi,
                quantityPresentation: i.multiplo,
                quantity: i.quantidade,
                number: i.numeroItem,
                code: i.numeroItem,
                total: i.precoTotal,
                order: i.ordem,
                totalQuantity: i.quantidadeTotal,
                promotion: i.promocoes,
                costModifiable: i.precoUnitario,
                valor: i.precoUnitario,
                semaforoCor: i.semaforoCor,
                semaforoNome: i.semaforoNome,
                suframa: i.valorSuframa,
                valorTrade: i.valorTrade,
                valorIpi: i.valorIpi,
                subtotal: i.precoTotal,
                subtotalipi: i.valorIpi,
                subtotalsemipi: i.precoTotal, // TODO: Verificar regra
            })),
        };
    }
}

export interface Iten {
    numeroItem: string;
    descricaoItem: string;
    quantidade: number;
    precoUnitario: number;
    precoTotal: number;
    multiplo: number;
    quantidadeTotal: number;
    desconto: number;
    promocoes: number;
    valorTrade: number;
    ipi: boolean;
    valorIpi: number;
    valorSuframa: number;
    ordem: string;
    semaforoCor: string;
    semaforoNome: string;
}

export interface ItensBonificado {
    numeroItem: string;
    descricaoItem: string;
    quantidade: number;
    precoUnitario: number;
    precoTotal: number;
    multiplo: number;
    quantidadeTotal: number;
    desconto: number;
    promocoes: number;
    valorTrade: number;
    ipi: boolean;
    valorIpi: number;
    valorSuframa: number;
    ordem: null;
    semaforoCor: string;
    semaforoNome: string;
}
