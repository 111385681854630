import React, { useEffect } from 'react';

import { Input } from '@components';
import { ShadowContainer } from '@styles/components/wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { DraftsTable } from '@redux/slices/order';
import { historyColumns, filterTypesStatus, filterTypes } from '../constants';
import { Table } from '@components/table';
import { FormItem, Option, Select } from '@styles/components/form';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { InputRange } from '@components/input';
import { translate } from '@components/i18n';
import { fetchTransferHistoryOrders, transferHistoryOrderSelector } from '@redux/slices/transfer-order/history';
import { TransferOrder } from '@models/transfer-order';
import { fetchMoneySymbols, fetchMoneyTypes, moneyTypeSelector } from '@redux/slices/money-type';
import { useParams } from '@hooks/use-params';
import { cleanObject } from '@utils/objects'
import { getMomentValidDate } from '@utils/date';

export const HistoryBody: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams<TransferOrder.Params>()
    const [form] = Form.useForm();

    const moneyType = useSelector(moneyTypeSelector);
    const historyState = useSelector(transferHistoryOrderSelector);


    const onFiltersChange = (changedValues: any, values: any): void => {
        if (changedValues && typeof changedValues === "object" && "filterValue" in changedValues) {
            return;
        };

        const params = {
            ...values,
            size: 20,
            page: 0,
        }

        if (values.datas && values.datas.length > 0 && values.datas[0]) {
            const datas = {
                dataInicio: values.datas[0].format("DD/MM/YYYY"),
                dataFim: values.datas[1].format("DD/MM/YYYY"),
            }
            form.setFieldsValue(datas)
            Object.assign(params, datas)
        }

        delete params.datas

        dispatch(fetchTransferHistoryOrders(params));
        const filterParams = new URLSearchParams(cleanObject(params))
        history.push(`?${filterParams.toString()}`);
    };


    const search = (): void => {
        const filtrs = {
            ...form.getFieldsValue(),
            size: 20,
            page: 0,
        }

        if (filtrs.datas && filtrs.datas.length > 0 && filtrs.datas[0]) {
            const datas = {
                dataInicio: filtrs.datas[0].format("DD/MM/YYYY"),
                dataFim: filtrs.datas[1].format("DD/MM/YYYY"),
            }
            Object.assign(filtrs, datas)
        }

        delete filtrs.datas

        const filterParams = new URLSearchParams(cleanObject(filtrs))
        history.push(`?${filterParams.toString()}`);

        dispatch(fetchTransferHistoryOrders(filtrs));
    };


    const onSelect = (rowKey: DraftsTable): void => {
        history.push(`/transfer-order/show?ref=${rowKey.id}`);
    };

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        _: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        const params: TransferOrder.Params = {
            page: pagination.current - 1,
            size: pagination.pageSize,
        }

        if (sorter.order && sorter.field) {
            Object.assign(params, {
                sort: `${sorter.field},${sorter.order.substring(0, 3)}`
            })
        }

        const filters = {
            ...form.getFieldsValue(),
            ...params
        }

        if (filters.datas && filters.datas.length > 0 && filters.datas[0]) {
            const datas = {
                dataInicio: filters.datas[0].format("DD/MM/YYYY"),
                dataFim: filters.datas[1].format("DD/MM/YYYY"),
            }

            delete filters.datas
            Object.assign(filters, datas)
        } else {
            delete filters.datas
        }

        dispatch(fetchTransferHistoryOrders(filters));
    };

    const start = (): void => {
        dispatch(fetchTransferHistoryOrders({
            ...params,
            size: 20,
            page: 0
        }));

        form.setFieldsValue({
            ...params,
            datas: [
                params.dataInicio ? getMomentValidDate(params.dataInicio) : null,
                params.dataInicio ? getMomentValidDate(params.dataFim) : null
            ]
        })

        if (!moneyType?.isSuccess) {
            dispatch(fetchMoneyTypes())
        }

        if (!moneyType?.isSuccessSymbols) {
            dispatch(fetchMoneySymbols())
        }
    };

    useEffect(() => {
        start();
    }, []);

    return (
        <ShadowContainer>
            <Row justify="center">
                <Col span={23}>
                    <Table
                        columns={historyColumns}
                        rowKey={(record: DraftsTable) => `${record.id}`}
                        pointer
                        dataSource={historyState?.available?.content || []}
                        pagination={{
                            current: (historyState?.available?.number || 0) + 1,
                            pageSize: historyState?.available?.size || 10,
                            total: historyState?.available?.totalElements || 0
                        }}
                        isLoading={historyState?.isFetching}
                        onFilterChange={onFiltersChange}
                        onRowClick={onSelect}
                        onChange={loadData}
                        form={form}
                        filters={[
                            <FormItem
                                label={translate('forms.labels.searchFor')}
                                name="filterType"
                            >
                                <Select
                                    placeholder={translate('forms.placeholders.chooseFilter')}
                                    allowClear
                                >
                                    {filterTypes.map((f) => {
                                        return (
                                            <Option
                                                key={f.name}
                                                value={f.name}>
                                                {f.description}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.date')}
                                name="datas"
                            >
                                <InputRange
                                    format="DD/MM/YYYY"
                                    placeholder={[
                                        translate('forms.placeholders.startDate'),
                                        translate('forms.placeholders.endDate'),
                                    ]}
                                    light
                                />
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.status')}
                                name="status"
                            >
                                <Select
                                    placeholder={translate('general.all')}
                                    dropdownMatchSelectWidth={false}
                                    allowClear
                                >
                                    {filterTypesStatus.map((c) => (
                                        <Option key={c.name} value={c.name}>
                                            {c.description}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>,
                            <Form.Item wrapperCol={{ span: 24 }} name="filterValue">
                                <Input
                                    placeholder={translate(
                                        'forms.placeholders.search',
                                    )}
                                    allowClear
                                    suffix={
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={search}
                                        >
                                            <SearchOutlined />
                                        </div>
                                    }
                                    onKeyUp={(e) => {
                                        if (
                                            e.code === `Enter` ||
                                            e.keyCode === 13
                                        ) {
                                            search();
                                        }
                                    }}
                                />
                            </Form.Item>,
                        ]}
                    />
                </Col>
            </Row>
        </ShadowContainer>
    );
};
