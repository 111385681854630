import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@hooks/use-query';
import { Card, Center } from '@styles/components/wrapper';
import { LeftOutlined } from '@ant-design/icons';
import {
    fetchAvailableTypes,
    fetchConvenio,
    fetchCustos,
    fetchMoedas,
    fetchMoedasSimbolos,
    fetchPdiscqt,
    fetchSemaphores,
    resetSelectItemsState,
} from '@redux/slices/order';
import { Page } from '@components/page';
import { clearStateCampaign } from '@redux/slices/campaign';
import { translate } from '@components/i18n';
import { CardHeader } from './components/card-header';
import { SelectTypeForm } from './components/select-type-form';


export const SelectType: React.FC = () => {
    const dispatch = useDispatch();
    const query = useQuery();
    const isDraft = query.get("draft")

    useEffect(() => {
        if (!isDraft) {
            dispatch(resetSelectItemsState());
        } else {
            dispatch(fetchAvailableTypes());
        }
        dispatch(fetchCustos());
        dispatch(fetchMoedas());
        dispatch(fetchMoedasSimbolos());
        dispatch(fetchConvenio());
        dispatch(fetchPdiscqt());
        dispatch(fetchSemaphores());
        dispatch(clearStateCampaign());
    }, []);

    return (
        <Page
            title={translate('pages.newOrder')}
            greyBackground
            onClickGoBack
            menuIcon={<LeftOutlined />}
        >
            <Center>
                <Card title={<CardHeader />} padding={'24px 15%'}>
                    <SelectTypeForm />
                </Card>
            </Center>
        </Page>
    );
};
