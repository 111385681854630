import { cleanObject } from '@utils/objects';
import useLocation from 'react-use/lib/useLocation';

export const useParams = <T>(): T => {
    const { search } = useLocation();

    const searchParams = new URLSearchParams(search);
    const paramsObject = {};

    searchParams.forEach((value, key) => {
      paramsObject[key] = value;
    });

    return cleanObject(paramsObject) as T
};
