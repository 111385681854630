import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'antd';
import { Description } from '@components/texts';
import { translate } from '@components/i18n';
import { Collapsable } from '../../order/components/styled';
import { transferOrderSelector } from '../../../redux/slices/transfer-order/order';
import styled from 'styled-components';

const { Panel } = Collapsable;

export const DeliveryClientCollapse: React.FC = memo(() => {
    const transferOrder = useSelector(transferOrderSelector);

    return (
        <Collapsable
            collapsible="disabled"
            expandIcon={() => <></>}
        >
            <Panel
                header={
                    <>
                        <Col>
                            {transferOrder?.ref && (
                                <Description
                                    text={`${translate('columns.order')}:`}
                                    description={transferOrder?.ref}
                                />
                            )}
                            <Description
                                text={`${translate('columns.client')}:`}
                                description={transferOrder?.deliveryClient?.name}
                            />
                            <Description
                                text={`${translate('columns.cnpj')}:`}
                                description={transferOrder?.deliveryClient?.cnpj}
                            />

                            {transferOrder.deliveryClient && (
                                <SelectCustomer>
                                    {translate('general.deliveryClient')}
                                </SelectCustomer>
                            )}
                        </Col>
                    </>
                }
                key='header'
            />
        </Collapsable>
    );
},
);



export const SelectCustomer = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 3px 5px;
    background: #f0eff0;
    border-radius: 20px;
    color: ${({ theme }) => theme.colors.darkBlue};
`;
