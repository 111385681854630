import moment from "moment";

export const getLastYears = (count): any[] => {
    const months = [];
    const year = new Date().getFullYear();
    for (let index = 0; index < count; index++) {
        const newYear = year - index;
        months.push({
            name: newYear,
            description: newYear,
        });
    }
    return months;
};


// date in DD/MM/YYYY -> moment out
export const getMomentValidDate = (date: string): moment.Moment => {
    const splitted = date.split("/")

    const newDateString = `${splitted[2]}-${splitted[1]}-${splitted[0]}`

    const momentDate = moment(newDateString);

    if(!momentDate.isValid()){
        throw new Error("Invalid date")
    }
    
    return momentDate;
}