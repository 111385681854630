import React from 'react';
import { Col, message, Row } from 'antd';
import { useHistory } from 'react-router-dom'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ShadowContainer } from '@styles/components/wrapper';
import { Text } from '@styles/components/text';
import '@extensions/number';
import { translate } from '@components/i18n';
import { copyTransferOrder, transferOrderSelector } from '@redux/slices/transfer-order/order';
import { Button } from '@styles/components/button';

export const TransferHistoryShowHeader: React.FC = () => {

    const history = useHistory()

    const dispatch = useDispatch()
    const transferOrder = useSelector(transferOrderSelector);

    const orderValues = !transferOrder?.order
        ? []
        : [
            {
                label: translate('columns.order'),
                value: transferOrder.order.id,
            },
            {
                label: translate('general.saleOrder'),
                value: transferOrder.order.pedidoVendaId || '-',
            },
            {
                label: translate('general.promoter'),
                value: transferOrder.order.promotorNome || '-',
            },
            {
                label: translate('columns.clientTransfer'),
                break: true,
                value: `${transferOrder.order.razaoSocial || ''}`,
            },
            {
                label: translate('columns.transferClientCity'),
                break: true,
                value: `${transferOrder.order.cidadeCliente}`,
            },
            {
                label: translate('columns.orderDate'),
                value: moment(transferOrder.order.dataPedido).format('DD/MM/YYYY'),
            },
            {
                label: translate('columns.distributor'),
                break: true,
                value: `${transferOrder.order.codigoDistribuidor || ''} - ${transferOrder?.order?.nomeDistribuidor || ''}`,
            },
            {
                label: translate('columns.distributorType'),
                break: true,
                value: `${transferOrder.order.tipoDistribuidor || ''}`,
            },
            {
                label: translate('general.comentary'),
                value: transferOrder.order.comentario || ''
            },
        ];

    const orderStatus = !transferOrder?.order
        ? []
        : [
            {
                label: translate('columns.status'),
                value: transferOrder.order.status ? translate(`columns.${transferOrder.order.status}`) : '',
            },
            {
                label: translate('columns.division'),
                value: transferOrder.order.divisao,
            },
            {
                label: translate('columns.branch'),
                value: `${transferOrder.order.codigoFilial} - ${transferOrder.order.descricaoFilial}`,
            },
            {
                label: translate('columns.moneyType'),
                value: `${transferOrder?.moneyType?.codigo} - ${transferOrder?.moneyType?.descricao}`
            }
        ];

    const copyOrder = (): void => {
        const key = new Date().getTime().toString()
        message.loading({
            content: translate('general.copyingOrder'),
            key: key,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(copyTransferOrder({
            data: transferOrder.order.id,
            notificationKey: key,
            onSuccess: (id) => {
                history.push(`/create-transfer-order/select-items?draft=true&ref=${id}`);
            },
        }));
    };

    return (
        <ShadowContainer style={{ padding: '1.5rem' }}>
            <Row>
                <Col span={24} lg={{ span: 20 }}>
                    <Row gutter={10}>
                        <Col span={24} lg={{ span: 8 }}>
                            {orderValues.map((c) => (
                                <Row style={{ marginTop: 10 }}>
                                    <Text fontWeight={'bold'} blue marginRight={'4px'}>
                                        {c.label}:
                                    </Text>
                                    <Text inline={!c.break} title={c.value?.toString()}>
                                        {c.value}
                                    </Text>
                                </Row>
                            ))}
                        </Col>
                        <Col span={24} lg={{ span: 6 }}>
                            {orderStatus
                                .filter((c) => c)
                                .map((c) => (
                                    <Row style={{ marginTop: 10 }}>
                                        <Text
                                            fontWeight={'bold'}
                                            blue
                                            marginRight={'4px'}
                                        >
                                            {c.label}:
                                        </Text>
                                        <Text title={c.value}>
                                            {c.value}
                                        </Text>
                                    </Row>
                                ))}
                        </Col>
                    </Row>
                </Col>
                <Col span={24} lg={{ span: 4 }}>
                    <Button
                        type="ghost"
                        height={40}
                        width={'100%'}
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        {translate('general.goBackList')}
                    </Button>
                    <Button
                        type="primary"
                        height={40}
                        width={'100%'}
                        style={{ marginTop: 10 }}
                        onClick={copyOrder}
                    >
                        {translate('forms.buttons.copyOrder')}
                    </Button>
                </Col>
            </Row>
        </ShadowContainer>
    );
};
