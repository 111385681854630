import React, { useEffect, useState } from 'react';
import { Col, Form, Popconfirm, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SorterResult } from 'antd/lib/table/interface';

import { Input, Page } from '@components';
import { ShadowContainer } from '@styles/components/wrapper';
import { Title } from '@styles/components/text';
import { Table } from '@components/table';
import { FormItem, Option, Select } from '@styles/components/form';
import { SearchOutlined } from '@ant-design/icons';
import { translate } from '@components/i18n';
import { TransferClient } from '@models/transfer-client';
import { fetchTransferDraftOrders, removeTransferDrafts, transferDraftOrderSelector } from '@redux/slices/transfer-order/draft';
import { TransferOrder } from '@models/transfer-order';
import { fetchMoneySymbols, moneyTypeSelector } from '@redux/slices/money-type';
import { clearTransferOrder } from '@redux/slices/transfer-order/order';
import { Button } from '@styles/components/button';
import { columns, filterTypesOrder } from './constants';

export const TransferOrderDrafts: React.FC = () => {
    const moneyType = useSelector(moneyTypeSelector)
    const transfer = useSelector(transferDraftOrderSelector);
    const [selectedRows, setSelectedRows] = useState<TransferOrder.Response[]>([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const [form] = Form.useForm();

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        _,
        sorter: SorterResult<unknown>,
    ): void => {
        const params: TransferOrder.Params = {
            page: pagination.current - 1,
            size: pagination.pageSize,
            filterType: form.getFieldValue("filterType"),
            filterValue: form.getFieldValue("filterValue"),
        }

        if (sorter.order && sorter.field) {
            Object.assign(params, {
                sort: `${sorter.field},${sorter.order.substring(0, 3)}`
            })
        }

        form.setFieldsValue({ page: params.page, size: params.size })

        dispatch(fetchTransferDraftOrders(params));
    };

    const onSelect = (rowKey: TransferOrder.Response): void => {
        dispatch(clearTransferOrder())

        history.push(`/create-transfer-order/select-items?ref=${rowKey.id}&draft=true`);
    };

    const search = (): void => {
        dispatch(fetchTransferDraftOrders({
            filterType: form.getFieldValue("filterType"),
            filterValue: form.getFieldValue("filterValue"),
            page: 0,
            size: transfer?.available?.size || 20,
        }));
    };

    const onChangeRowSelection = (_: React.Key[], selectedRows: TransferOrder.Response[],): void => {
        setSelectedRows(selectedRows);
    }

    const deleteDrafts = (): void => {
        const notificationKey = new Date().getTime().toString()

        dispatch(removeTransferDrafts({
            data: selectedRows,
            notificationKey,
            onSuccess: () => {
                setSelectedRows([]);
                dispatch(fetchTransferDraftOrders({
                    filterType: form.getFieldValue("filterType"),
                    filterValue: form.getFieldValue("filterValue"),
                    page: form.getFieldValue("page") || 0,
                    size: transfer?.available?.size || 20,
                }));
            },
        }))

    };


    useEffect(() => {
        dispatch(fetchTransferDraftOrders({
            page: 0,
            size: transfer?.available?.size || 20,
        }))

        const filters = Object.fromEntries(
            new URLSearchParams(location.search),
        );

        if (!moneyType?.isSuccessSymbols) {
            dispatch(fetchMoneySymbols())
        }

        setTimeout(() => {
            form.setFieldsValue(filters);
        }, 1000);
    }, []);

    return (
        <Page
            title={translate('pages.draft')}
            greyBackground
            withPadding
        >
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row>
                        <Title marginTop={20} level={3}>
                            {translate('titlePages.draft')}
                        </Title>
                    </Row>
                    <ShadowContainer>
                        <Row justify="center">
                            <Col span={23}>
                                <Table
                                    columns={columns}
                                    form={form}
                                    pointer
                                    rowKey={(record: TransferClient.Response) => `${record.id}`}
                                    rowSelection={{
                                        onChange: onChangeRowSelection,
                                        columnWidth: '1.25rem',
                                        fixed: true,
                                    }}
                                    dataSource={transfer?.available?.content || []}
                                    onRowClick={onSelect}
                                    pagination={{
                                        current: (transfer?.available?.number || 0) + 1,
                                        pageSize: transfer?.available?.size || 10,
                                        total: transfer?.available?.totalElements || 0
                                    }}
                                    isLoading={transfer?.isFetching}
                                    onChange={loadData}
                                    filters={[
                                        <FormItem
                                            label={translate('forms.labels.searchFor')}
                                            name={'filterType'}
                                        >
                                            <Select
                                                placeholder={translate('general.all')}
                                                dropdownMatchSelectWidth={false}
                                                allowClear
                                                onChange={e => form.setFieldsValue({ filterType: e })}
                                            >
                                                {filterTypesOrder.map((f) => {
                                                    return (
                                                        <Option
                                                            key={f.name}
                                                            value={f.name}
                                                        >
                                                            {f.description}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </FormItem>,
                                        <Form.Item wrapperCol={{ span: 24 }}>
                                            <Input
                                                placeholder={translate('forms.placeholders.search')}
                                                allowClear
                                                name='filterValue'
                                                suffix={
                                                    <div
                                                        onClick={search}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <SearchOutlined />
                                                    </div>
                                                }
                                                onKeyUp={(e) => {
                                                    if (
                                                        e.code === `Enter` ||
                                                        e.keyCode === 13
                                                    ) {
                                                        search();
                                                    }
                                                }}
                                                onChange={(e) =>
                                                    form.setFieldsValue({ filterValue: e.target.value })
                                                }
                                            />
                                        </Form.Item>,
                                    ]}
                                />
                            </Col>
                        </Row>

                        {selectedRows.length > 0 && (
                            <Row justify="end" style={{ paddingBottom: 20, marginRight: '2rem' }}>
                                <Col>
                                    <Popconfirm
                                        title={
                                            <span>
                                                {translate('general.orderSelectedModalFirstPart')}
                                                <br />{' '}
                                                {translate('general.orderSelectedModalSecondPart')}
                                            </span>
                                        }
                                        style={{ whiteSpace: 'break-spaces' }}
                                        okText={translate('general.yes')}
                                        cancelText={translate('general.no')}
                                        onConfirm={deleteDrafts}
                                    >
                                        <Button danger type="primary" height={40}>
                                            {translate('general.delete')}
                                        </Button>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        )}
                    </ShadowContainer>
                </Col>
                <Col span={1} />
            </Row>
        </Page>
    );
};
