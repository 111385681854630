import React, { memo } from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { SolidBackground } from '@styles/components/wrapper';
import { transferOrderSelector } from '@redux/slices/transfer-order/order';
import { ClientCollapse } from './client-collapse';
import { DistributorCollapse } from './distributor-collapse';
import { SelectItemsFilters } from './select-items-filters';
import { SelectItemsHeaderFooter } from './select-items-header-footer';
import { DeliveryClientCollapse } from './delivery-client-collapse';

export const TransferSelectItemsHeader: React.FC = memo(() => {
    const transferOrder = useSelector(transferOrderSelector)
    const hasDeliveryClient = !!transferOrder?.deliveryClient

    return (
        <Col>
            <SolidBackground isWhite>
                <Row style={{ padding: 30 }}>
                    <Col
                        xs={24}
                        lg={12}
                        xxl={hasDeliveryClient ? 6 : 8}
                    >
                        <ClientCollapse />
                    </Col>
                    <Col
                        xs={24}
                        lg={12}
                        xxl={hasDeliveryClient ? 6 : 8}
                    >
                        <DistributorCollapse />
                    </Col>
                    {hasDeliveryClient && (
                        <Col
                            xs={24}
                            lg={8}
                            xxl={6}
                        >
                            <DeliveryClientCollapse />
                        </Col>
                    )}
                    <Col
                        xs={24}
                        lg={8}
                        xxl={hasDeliveryClient ? 6 : 8}
                    >
                        <SelectItemsFilters />
                    </Col>
                </Row>
            </SolidBackground>
            <SelectItemsHeaderFooter />
        </Col>
    );
},
);
