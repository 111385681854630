import axios from '@axios';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from '@redux/reducers';
import {  UserResponseApi } from "@models/users";
import { translate } from "@components/i18n";
import { addNotification } from "../notification";

export const fetchSellers = createAsyncThunk<
  UserResponseApi[],
  number
>('select-seller/fetch-sellers', async (pedidoId, thunkAPI) => {
    try {
        const response = await axios.get<UserResponseApi[]>(`/pedido/listavendedor/${pedidoId}`);
        const { data, status} = response;

        const mapped = data.map(user => Object.assign(new UserResponseApi(), user))

        if(status === 200){
          return mapped
        }
        
        thunkAPI.dispatch(
          addNotification({
              type: 'error',
              message: translate('general.erroListing'),
              title: translate('general.error'),
          }),
      );
        
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

type TChangeSeller = {
  codigoJDE: number
  pedidoId: number
}

interface ISellerSelectorState {
  availableSellers: UserResponseApi[];
  selectedSeller?: UserResponseApi
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
}

const initialState: ISellerSelectorState = {
  availableSellers: [],
  isFetching: true,
  isSuccess: false,
  isError: false,
  errorMessage: '',
};

const selectSellerSlice = createSlice({
  name: 'selectSellerSlice',
  initialState,
  reducers: {
    resetSellerState: (state) => {
      state = { ...initialState };
      return state;
    },
    updateSeller: (state, action: PayloadAction<UserResponseApi>) => {
      state.selectedSeller = action.payload
      return state;
    },
  },
  extraReducers: {
    [fetchSellers.rejected.toString()]: (state, action) => {
        state.isFetching = false;
        state.isSuccess = false;
    },
    [fetchSellers.pending.toString()]: (state) => {
        state.isFetching = true;
        state.isSuccess = false;
    },
    [fetchSellers.fulfilled.toString()]: (
        state,
        { payload }: PayloadAction<UserResponseApi[]>,
    ) => {
        state.availableSellers = payload;
        state.isFetching = false;
        state.isSuccess = true;
        return state;
    },
  },
});

export const { resetSellerState, updateSeller } = selectSellerSlice.actions;

export const sellerSelector = (state: RootState): ISellerSelectorState => state.selectSeller;


export default selectSellerSlice.reducer;
