import { translate } from '@components/i18n';

export const columns = [
    {
        title: translate('columns.client'),
        dataIndex: 'razaoSocial',
        sorter: true,
        width: '15%',
    },
    {
        title: translate('columns.cnpj'),
        dataIndex: 'cnpj',
        sorter: true,
        width: '15%',
    },
    {
        title: translate('columns.email'),
        dataIndex: 'email',
        sorter: true,
        width: '15%'
    },
    {
        title: translate('columns.distributor'),
        dataIndex: 'codigoDistribuidor',
        sorter: true,
        width: '15%',
        render: (_, record): JSX.Element => {
            const codeName = `${record.codigoDistribuidor} - ${record.nomeDistribuidor || ''}`
            return (
                <span>{codeName}</span>
            )
        }
    },
    {
        title: translate('columns.city'),
        dataIndex: 'cidade',
        sorter: true,
        width: '15%',
    },
    {
        title: translate('columns.state'),
        dataIndex: 'zona',
        sorter: true,
        width: '10%',
    },
];

export const filterTypes = [
    {
        name: 'TODOS',
        description: translate('filters.all'),
    },
    {
        name: 'CNPJ',
        description: translate('filters.cnpj'),
    },
    {
        name: 'CLIENTE',
        description: translate('filters.client'),
    },
    {
        name: 'DISTRIBUIDOR',
        description: translate('filters.distributor'),
    },
    {
        name: 'PROMOTOR',
        description: translate('filters.promoter'),
    },
];

export const filterTypesOrder = [
    {
        name: 'CNPJ_CLIENTE',
        description: translate('filters.cnpj'),
    },
    {
        name: 'NOME_CLIENTE',
        description: translate('filters.name'),
    },
    {
        name: 'CODIGO_CLIENTE',
        description: translate('filters.clientCode'),
    },
    {
        name: 'CIDADE_CLIENTE',
        description: translate('filters.city'),
    },
    {
        name: 'ZONA_CLIENTE',
        description: translate('filters.state'),
    },
    {
        name: 'TIPO_PEDIDO',
        description: translate('filters.orderType'),
    },
];

export const filterTypesOrderHistory = [
    {
        name: 'CNPJ_CLIENTE',
        description: 'CNPJ',
    },
    {
        name: 'NOME_CLIENTE',
        description: 'Nome',
    },
    {
        name: 'PEDIDO',
        description: 'Pedido',
    },
    {
        name: 'CODIGO_CLIENTE',
        description: 'Código do Cliente',
    },
    {
        name: 'CIDADE_CLIENTE',
        description: 'Cidade',
    },
    {
        name: 'ZONA_CLIENTE',
        description: 'Estado',
    },
    {
        name: 'TIPO_PEDIDO',
        description: 'Tipo de Pedido',
    },
];

export const filterOptions = [
    { value: 'all', label: 'Todos' },
    { value: 'name', label: 'Nome' },
    { value: 'code', label: 'Código' },
];

export const filterOptionsTranspo = [
    { value: 'all', label: translate('filters.all') },
    { value: 'name', label: translate('filters.name') },
    { value: 'codigoTipoTransporte', label: translate('filters.transport') },
];

export const filterModalSelectOptions = [
    { value: 'todos', label: translate('filters.all') },
    { value: 'nome', label: translate('filters.name') },
    { value: 'codigo', label: translate('filters.code') },
];
