import React, { memo, useContext } from 'react';
import { Col, Form, Input, message, notification, Row } from 'antd'; import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Prompt } from 'react-router';
import { SolidBackground } from '@styles/components/wrapper';
import { Button } from '@styles/components/button';
import { useQuery } from '@hooks/use-query';
import { translate } from '@components/i18n';
import { clearTransferOrder, deleteTransferOrder, mutationTransferOrder, transferOrderSelector } from '@redux/slices/transfer-order/order';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FormItem } from '@styles/components/form';
import { SendCopyRequest } from '@models/order/items/request';
import { OrderContext } from '../../order/context/context';
import { ChangeBranchTransfer } from './change-branch';
import { IS_ARG } from '@config/env';

const { confirm } = Modal;

export const SelectItemsActions: React.FC = memo(() => {
    const history = useHistory();
    const query = useQuery();

    const isReadOnly = query.get('readOnly') === 'true';
    const isDraft = query.get('draft') === 'true';

    const dispatch = useDispatch();
    const transferOrder = useSelector(transferOrderSelector);

    const canSave = transferOrder?.availableItems?.some((i) => i.quantity > 0)

    const [form] = Form.useForm<SendCopyRequest>();

    const { canLeave, setCanLeave } = useContext(OrderContext);

    const cancelOrder = (): void => {
        const title = `${translate('general.wantToCancel')}${transferOrder?.ref ? translate('general.edition') : translate('general.creation')}`

        confirm({
            title: title,
            icon: < ExclamationCircleOutlined />,
            className: 'confirm-modal-styled',
            okText: translate('general.no'),
            okType: 'danger',
            cancelText: translate('general.yes'),
            okButtonProps: { type: 'primary' },
            cancelButtonProps: { type: 'primary', className: 'green-btn' },
            onOk() {
                console.debug('OK');
            },
            onCancel() {
                setCanLeave(() => history.push('/'))
            },
        });
    };

    const deleteOrder = (): void => {
        const title = `${translate('general.wantToDelete')}`

        confirm({
            title: title,
            icon: < ExclamationCircleOutlined />,
            className: 'confirm-modal-styled',
            okText: translate('general.no'),
            okType: 'danger',
            cancelText: translate('general.yes'),
            okButtonProps: { type: 'primary' },
            cancelButtonProps: { type: 'primary', className: 'green-btn' },
            onOk() {
            },
            onCancel() {
                const notificationKey = new Date().getTime().toString();
                dispatch(deleteTransferOrder({
                    data: transferOrder.ref,
                    notificationKey,
                    onSuccess: () => {
                        setCanLeave(() => history.replace('/transfer-order/drafts'));
                    },
                }))
            },
        });
    };

    const saveOrder = (isDraft?: boolean): void => {
        if (form.getFieldError('email').length > 0) {
            notification.error({ message: form.getFieldError('email')[0] })
            return
        }

        const email = form.getFieldValue('email')
        const note = form.getFieldValue('note')

        const notificationKey = transferOrder.ref || new Date().getTime().toString();

        message.loading({
            content: isDraft ? translate('general.savingDraft') : translate('general.sendingOrder'),
            key: notificationKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(mutationTransferOrder({
            data: {
                email,
                note,
                isDraft,
            },
            notificationKey,
            onSuccess: () => {
                setCanLeave(() => history.push('/'));
                dispatch(clearTransferOrder())
            },
        }))
    };

    return (
        <SolidBackground
            marginTop={20}
            marginBottom={80}
            paddingBottom={10}
            paddingX={'40px'}
        >
            <Prompt
                when={!isReadOnly && !canLeave}
                message={`${translate('general.wantExitOf')} ${isDraft
                    ? translate('general.edition')
                    : translate('general.creation')
                    } ${translate('general.toOrder')}`}
            />

            <Row justify="space-between" align="middle">
                <Col span={10}>
                    <Form<SendCopyRequest>
                        layout="horizontal"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 24 }}
                        form={form}
                        initialValues={{
                            email: transferOrder?.order?.emailCopia || '',
                            note: transferOrder?.order?.comentario || ''
                        }}
                    >
                        <Row gutter={12}>
                            <Col xs={24} lg={10}>
                                <FormItem
                                    label={translate('forms.labels.sendCopyFor')}
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: translate(
                                                'forms.rules.validEmail',
                                            ),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={translate('forms.placeholders.email')}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} lg={14}>
                                <FormItem
                                    label={`${translate('forms.labels.observation')}:`}
                                    name="note"
                                >
                                    <Input
                                        placeholder={translate('forms.labels.observation')}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Row gutter={10}>
                    {isDraft && IS_ARG && transferOrder?.order?.pedidoCopia && <ChangeBranchTransfer />}
                    {isDraft && (
                        <Col>
                            <Button
                                danger
                                height={40}
                                onClick={deleteOrder}
                            >
                                {translate('general.delete')}
                            </Button>
                        </Col>
                    )}
                    <Col>
                        <Button
                            danger
                            type="primary"
                            height={40}
                            onClick={cancelOrder}
                        >
                            {translate('general.cancel')}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            darkBlue
                            height={40}
                            onClick={() => saveOrder(true)}
                            disabled={!canSave}
                        >
                            {translate('general.saveDraft')}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            height={40}
                            disabled={!canSave}
                            onClick={() => saveOrder()}
                        >
                            {translate('general.send')}
                        </Button>
                    </Col>
                </Row>
            </Row>
        </SolidBackground>
    );
},
);
