import styled, { css } from 'styled-components';
import { margin, MarginProps } from 'styled-system';
import { Form, Input, Select as AntSelect, Checkbox as AntCheckbox } from 'antd';
const { Option: AntOption } = AntSelect;
const { Search: AntSearch } = Input;

interface FormItemProps extends MarginProps {
    onlyValidation?: boolean;
    vertical?: boolean;
}

export const FormItem = styled(Form.Item)<FormItemProps>`
    flex-direction: column;
    justify-content: center;
    max-width: 100%;

    ${margin};

    ${({ vertical }) =>
        vertical &&
        css`
            .ant-form-item-label label {
                overflow: visible;
                display: inherit;
                height: 25px;
                margin-bottom: 78px;
            }
        `}

    ${({ onlyValidation }) =>
        onlyValidation &&
        css`
            margin-bottom: 10px;
            .ant-form-item-control-input {
                display: none;
            }
        `}
    .ant-form-item-label {
        display: flex !important;
    }

    .ant-form-item: {
        max-width: 100%;
    }

    .ant-form-item-label,
    .ant-form-item-control {
        width: 100%;
        max-width: 100%;
    }
`;

interface SelectProps {
    adjustableHeight?: boolean;
}

export const Select = styled(AntSelect)<SelectProps>`
    .ant-select-selector {
        border-radius: 4px !important;
        background-color: ${(props) => props.theme.colors.white} !important;
        border: 1px solid #666666 !important;
        height: 40px !important;
        padding: 0px 15px !important;

        ${({ adjustableHeight }) =>
            adjustableHeight &&
            css`
                height: auto !important;
                min-height: 40px !important;
            `}
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
        display: flex;
        align-items: center;
    }

    &.ant-select-disabled {
        background-color: #d9d9d9 !important;

        .ant-select-selector {
            background-color: #d9d9d9 !important;
        }
    }
`;

export const Option = styled(AntOption)``;

export const Checkbox = styled(AntCheckbox)``;

export const Search = styled(AntSearch)`
    border-radius: 4px !important;
    background-color: ${(props) => props.theme.colors.white} !important;
    border: 1px solid #666666 !important;
    height: 40px !important;
    width: 20vw;

    .ant-input-affix-wrapper,
    button {
        border: none !important;
    }

    .ant-input-affix-wrapper-focused {
        box-shadow: none !important;
        z-index: 0 !important;
    }

    button {
        border: none;
        border-left: 1px solid !important;
    }

    .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
    }
`;
